import { Controller } from "@hotwired/stimulus"
import {Modal} from "bootstrap";

// Connects to data-controller="talents--modal-confirmation"
export default class extends Controller {
  static targets = ['modal']

  connect() {
    this.loadModal()
    this.autoOpen()
  }

  loadModal() {
    if (!this.hasModalTarget) return

    new Modal(this.modalTarget)
  }

  autoOpen() {
    if (typeof selectionProcessCommentIsInvalid === 'undefined') return

    selectionProcessCommentIsInvalid && Modal.getInstance(this.modalTarget).show()
  }

  open(event) {
    event.preventDefault()

    Modal.getInstance(this.modalTarget).show()
  }

  submit() {
    Modal.getInstance(this.modalTarget).hide()
  }

  close() {
    Modal.getInstance(this.modalTarget).hide()
  }
}
